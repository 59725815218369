<template>
  <el-dialog
    top="2em"
    width="70%"
    :visible.sync="dialogVisible"
    append-to-body
    class="types__second-equipments-modal"
    :close-on-click-modal="false"
  >
    <div class="my-flex-container-column">
      <div slot="title" class="pt-0 pb-0">
        Первичное оборудование/ЛЭП
      </div>
      <primary-equipments-select-filter 
        @active-change="filterActive = $event" 
        :with-line="!filterModel.hideLines"
        :filter-model="filterModel" 
        @clear-filters="clearFilters"/>
      <primary-equipments-select-table
        :data="selectedPrimaryEquipments"
        :filter-active="filterActive"
        :filter-model="filterModel"
        @on-update="selectedPrimaryEquipments = $event" />
    </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="selectPrimaryEquipments">Применить</el-button>
        <el-button type="default" @click="cancelSelectPrimaryEquipments">Отменить</el-button>
      </div>
  </el-dialog>
</template>

<script>
import PrimaryEquipmentsSelectFilter from '@/views/Global/PrimaryEquipments/PrimaryEquipmentsFilter.vue';
import PrimaryEquipmentsSelectTable from './PrimaryEquipmentsSelectTable';

export default {
  name: 'PrimaryEquipmentsTreeModal',
  props: ['value', 'data'],
  components: { PrimaryEquipmentsSelectTable, PrimaryEquipmentsSelectFilter },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  created() {
    this.selectedPrimaryEquipments = this.data && this.data.length > 0 ? this.data.map((t) => ({ baseId: t.id, name: t.value, ...t })) : [];
  },
  data() {
    return {
      selectedPrimaryEquipments: [],
      filterModel: {
        nameFilter: '',
        energyObjectIdFilter: [],
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        primaryEquipmentTypeIdFilter: [],
        eoRegionFilter: [],
        skipEoObject: false,
        isLocalNsiFilter: false,
        isRuFilter: false,
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        hideLines: false
      },
      filterActive: true,
    };
  },
  methods: {
    selectPrimaryEquipments() {
      this.$emit('on-update', this.selectedPrimaryEquipments);
      this.dialogVisible = false;
    },
    cancelSelectPrimaryEquipments() {
      this.dialogVisible = false;
    },
    clearFilters() {
      this.filterModel = {
        nameFilter: '',
        energyObjectIdFilter: [],
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        primaryEquipmentTypeIdFilter: [],
        eoRegionFilter: [],
        skipEoObject: false,
        isLocalNsiFilter: false,
        isRuFilter: false,
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        hideLines: false
      };
    }
  }
};
</script>

<style lang="scss">
.types__second-equipments-modal {
  min-height: 1000px;

  .el-dialog__header {
    padding-bottom: 0px;
  }
  .el-dialog__body {
    padding: 0px 10px 0px 10px;
    height: 80vh;
    max-height: 80vh;
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>
