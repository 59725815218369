<template>
  <div class="my-flex-container-column">
    <el-row>
      <div class="selected-tags">
        <el-tag
            v-if="selectedSecondEquipmentPanel.id"
            :key="selectedSecondEquipmentPanel.id"
            closable
            size="small"
            @close="handleSecondEquipmentPanelDelete"
            class="mr-1 mb-1">
            {{selectedSecondEquipmentPanel.name}}
        </el-tag>
      </div>
    </el-row>
    <el-row class="m-0" :gutter="20">
      <el-col>
        <pagination
            style="text-align: center"
            :total-count="totalCount"
            :page-size.sync="pageSize"
            :current-page.sync="pageNumber"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
    <div>
      <el-button @click="toggleAllNodes" type="text" class="ml-2">Свернуть/развернуть всё</el-button>
      <span v-if="!this.filterModel.skipEoObject" class="total_items">Всего: {{this.secondEquipmentPanelsCount}}</span>
    </div>
    <div v-loading="loading"  class="my-flex-data-content">
      <infinite-tree
        ref="tree"
        :data="secondEquipmentPanels"
        :style="{'height' : '100%'}"
        @on-check-click="handleCheck"
        @on-open-node="applyChecking"
      >
        <div class="infinite-tree-node" slot-scope="{ node }">{{ node.color }}</div>
      </infinite-tree>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import secondEquipmentPanelsApi from '@/api/nsi/secondEquipmentPanels';
import pagination from '@/components/Ui/Pagination';
import infiniteTree from '@/components/Ui/InfiniteTree/InfiniteTree';

export default {
  name: 'SecondEquipmentPanelsTable',
  props: {
    filterModel: { type: Object, default: () => {} },
    filterActive: { type: Boolean, default: () => true },
    data: { type: Object, default: () => {} },
  },
  components: {
    pagination,
    infiniteTree
    },
  data() {
    return {
      loading: false,
      secondEquipmentPanels: [],
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      dialogVisible: false,
      selectedSecondEquipmentPanel: {},
      secondEquipmentPanelsCount: 0,
    };
  },
  watch: {
    filterModel: {
      handler() {
        this.getSecondEquipmentPanels();
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
    treeContainer() {
        return this.$refs.tree;
    }
  },
  created() {
    if (this.data) {
      this.selectedSecondEquipmentPanel = this.data;
    }
  },
  async mounted() {
    await this.getSecondEquipmentPanels();
  },
  methods: {
    async getSecondEquipmentPanels() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        nameFilter: this.filterModel.nameFilter,
        eoIdFilter: this.filterModel.eoIdFilter,
        productAssetModelIdFilter: this.filterModel.productAssetModelIdFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        duOrganizationName: [],
        dvOrganizationName: [],
        baseVoltageFilter: [],
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        registrationGroupFilter: this.filterModel.registrationGroupFilter,
        executionsFilter: this.filterModel.executionsFilter,  
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter,
        technicalOrganizationsFilter: this.filterModel.technicalOrganizationsFilter,
        startCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.filterModel.periodCommissioningTimeRange[0] : null,
        endCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.filterModel.periodCommissioningTimeRange[1] : null,
        startDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.filterModel.periodDecommissioningTimeRange[0] : null,
        endDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.filterModel.periodDecommissioningTimeRange[1] : null,
        showDecommissionedSE: this.filterModel.showDecommissionedSE
      };
      const res = await secondEquipmentPanelsApi.getSecondEquipmentPanelsWithPagination(params);
      if (res.data) {
        this.secondEquipmentPanels = res.data.items;
        this.totalCount = res.data.totalCount;
        this.secondEquipmentPanelsCount = res.data.treeItemsCount;

        this.treeContainer.tree.loadData(this.secondEquipmentPanels);
        this.applyChecking();
      }
      this.loading = false;
    },
    applyChecking() {
      const { nodes } = this.treeContainer.tree;
      nodes.forEach((node) => {
        if (node.nodeType === 'secondEquipmentPanel') {
          if (node.baseId === this.data.id) {
            node.state.checked = true;
            node.parent.state.checked = node.state.checked;
          } else {
            node.state.checked = false;
          }
          node.сheckAllowed = true;
          node.viewAllowed = false;
          node.parent.сheckAllowed = false;
        } else {          
          node.сheckAllowed = false;
          node.viewAllowed = false;
        }
      });

      this.treeContainer.rerender();
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getSecondEquipmentPanels();
    },
    async handleCurrentChange() {
      await this.getSecondEquipmentPanels();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }

      await this.getSecondEquipmentPanels();
    },
    handleSecondEquipmentPanelDelete() {
        this.selectedSecondEquipmentPanel = {};
        this.$emit('on-update', this.selectedSecondEquipmentPanel);
    },
    toggleAllNodes() {
      this.$refs.tree.toggleNodes();
      this.applyChecking();
    },
    handleCheck(node) {
      if (node.state.checked && node.nodeType === 'secondEquipmentPanel') {
        this.selectedSecondEquipmentPanel = node;
        this.treeContainer.tree.nodes.forEach((n) => {
          n.state.checked = false;
        });
        const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'secondEquipmentPanel' && t.baseId === node.baseId);
        if (nodes && nodes.length > 0) {
          nodes.forEach((n) => {
            n.state.checked = true;
          });

          this.treeContainer.rerender();
        }
      } else {
        if (this.selectedSecondEquipmentPanel.baseId === node.baseId) {
          this.selectedSecondEquipmentPanel = {};

          const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'secondEquipmentPanel' && t.baseId === node.baseId);
          if (nodes && nodes.length > 0) {
            nodes.forEach((n) => {
              n.state.checked = false;
            });

            this.treeContainer.rerender();
          }
        }
      }
      this.$emit('on-update', this.selectedSecondEquipmentPanel);
    }
  }
};
</script>

<style scoped lang="scss">
.el-dialog__wrapper.el-dialog.el-dialog__body{
  padding: 20px;
}
.el-radio-group {
    font-size: initial !important;
}
.el-radio-group {
  width: 100%;
}
span.el-radio__label {
  padding-left: 5px;
}
span.el-radio__label>span {
  padding: 0px 5px;
}
span.el-radio__inner {
  margin: 0px 0px 9px 0px;
}
.el-tabs__header.is-top {
  margin: 0px !important;
}
button.el-button.el-button--text:first-child {
  margin-left: 0px;
}
.el-col>span {
  font-size: 12px;
}
button.el-button.el-button--text:hover {
  color: #3078bf;
}
.el-select {
  width: 100%;
}
span {
  //white-space: nowrap;
  vertical-align: middle;
}
.dialog-footer {
  text-align: center;
}
.scrollableTree {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 420px;
}
.tree-item>span{
  font-size: 14px;
}
.tree-item>span>i {
  margin: 0px 5px 0px 0px;
}
.total_items {
   float: right; 
   color: #909399;
   font-family: Montserrat-Regular;
   font-size: 14px;
   margin-top: 12px;
   margin-right: 8px;
}
</style>
