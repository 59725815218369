<template>
  <el-dialog v-if="dialogVisible"
             :visible.sync="dialogVisible"
             width="50%"
             class="nsi-energy-object"
             top="2rem"
             :close-on-click-modal="false">
    <div slot="title">
      Функции устройств РЗА
      <template v-if="userCanEdit">
        <el-button v-if="!editMode" class="back_action_panel--first_element" @click="handleEdit"
                   type="text">
          <i class="el-icon-edit"></i> Редактировать
        </el-button>
        <el-button v-if="editMode" class="back_action_panel--first_element custom-button "
                   @click="handleSave" type="text">
          <SaveIcon/>
          Сохранить
        </el-button>
        <el-button @click="handleDelete(secondEquipment.id)" :disabled="secondEquipment.isAip || secondEquipment.id == null"
                   type="text" icon="el-icon-delete">
          Удалить
        </el-button>
      </template>
    </div>
    <el-form :rules="rules" v-loading="loading" ref="secondEquipmentForm" :model="secondEquipment"
             label-width="18rem">
      <el-form-item prop="isAip" label="СК-11:">
        <el-checkbox v-model="secondEquipment.isAip"
                     disabled>
        </el-checkbox>
      </el-form-item>
      <el-form-item prop="analysisIds" label="Идентификатор ПК &quot;Анализ 2009&quot;:">
        <el-select v-model="secondEquipment.analysisIds"
                   multiple
                   filterable
                   allow-create
                   default-first-option
                   placeholder=""
                   :disabled="!editMode">
        </el-select>
      </el-form-item>
      <el-form-item prop="mRid" label="mRid:">
        <el-input v-model="secondEquipment.mRid"
          :disabled="!editMode || secondEquipment.isAip">
        </el-input>
      </el-form-item>
      <el-form-item prop="name" label="Наименование:">
        <el-input v-model="secondEquipment.name"
                  :disabled="!editMode || secondEquipment.isAip">
        </el-input>
      </el-form-item>
      <el-form-item prop="psrTypeId" label="Тип:">
        <el-select v-model="secondEquipment.psrTypeId" @change="handleChange" size="large"
                   filterable :disabled="!editMode || secondEquipment.isAip">
          <el-option v-for="item in secondEquipmentPsrTypes"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item  prop="baseVoltageId" label="Класс напряжения:">
        <el-select v-model="secondEquipment.baseVoltageId" :disabled="!editMode || secondEquipment.isAip"
                   placeholder="Без напряжения"
                   filterable
                   clearable>
          <el-option
            v-for="item in baseVoltages"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="secondEquipmentPanelId" label="Устройство РЗА:">
        <div class="selected-tags">
          <el-tag v-if="secondEquipment.secondEquipmentPanelId"
                  :key="secondEquipment.secondEquipmentPanelId"
                  size="small"
                  class="mr-1 mb-1">
            {{ secondEquipment.secondEquipmentPanelName }}
          </el-tag>
          <el-button v-if="editMode && !secondEquipment.isAip" type="primary" size="small"
                     @click="handleSecondEquipmentPanelsSelect">
            Выбрать
          </el-button>
        </div>
        <!-- <remote-search-input
          v-model="secondEquipment.secondEquipmentPanelId"
          searchUrl="/api/secondEquipmentPanels/SearchByName"
          return-prop="id"
          size="large"
          :allow-create=false
          :show-when-init="true"
          :initial-value="secondEquipment.secondEquipmentPanelName"
          :start-length=3
          :disabled="!editMode || secondEquipment.isAip"
        /> -->
      </el-form-item>
      <el-form-item prop="primaryEquipmentIds" label="Первичное оборудование/ЛЭП:">
        <div class="selected-tags">
          <el-tag v-for="tag in secondEquipment.primaryEquipmentIdsInit"
                  :key="tag.id"
                  size="small"
                  class="mr-1 mb-1">
            {{ tag.value }}
          </el-tag>
          <el-button v-if="editMode && !secondEquipment.isAip" type="primary" size="small"
                     @click="handlePrimaryEquipmentsSelect">
            Выбрать
          </el-button>
        </div>
        <!-- <remote-search-input
          v-model="secondEquipment.primaryEquipmentIds"
          searchUrl="/api/primaryEquipments/SearchByName"
          return-prop="id"
          size="large"
          :allow-create=false
          :show-when-init="true"
          :initial-value="secondEquipment.primaryEquipmentIdsInit"
          :multiple="true"
          :start-length=3
          :disabled="!editMode || secondEquipment.isAip"
        /> -->
      </el-form-item>
      <el-form-item v-if="isShowFreq" prop="frequency" label="Частота (кГц):">
        <el-input v-model="secondEquipment.frequency" type="number" step="0.01" min="0"
                  :disabled="!editMode">
        </el-input>
      </el-form-item>
      <el-form-item prop="startDate" label="Дата ввода в эксплуатацию:">
        <el-date-picker type="date"
                        v-model="secondEquipment.startDate"
                        v-mask="'##.##.####'"
                        style="width: 40%;"
                        format="dd.MM.yyyy"
                        value-format="yyyy-MM-dd"
                        :disabled="!editMode"
                        :picker-options="datePickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="endDate" label="Дата вывода из эксплуатации:">
        <el-date-picker type="date"
                        v-model="secondEquipment.endDate"
                        v-mask="'##.##.####'"
                        format="dd.MM.yyyy"
                        value-format="yyyy-MM-dd"
                        style="width: 40%;"
                        :disabled="!editMode"
                        :picker-options="datePickerOptions">
        </el-date-picker>
      </el-form-item>
      <!-- <el-form-item prop="normallyInService" label="В работе:">
        <el-checkbox v-model="secondEquipment.normallyInService"
                     :disabled="!editMode || secondEquipment.isAip">
        </el-checkbox>
      </el-form-item> -->
      <el-form-item prop="technicalOrganizations" label="Технический учёт ДЦ:">
        <!-- <remote-search-input v-model="secondEquipment.technicalOrganizations"
                             searchUrl="/api/organizations/SearchByName"
                             size="large"
                             return-prop="id"
                             :allow-create="false"
                             :multiple="true"
                             :initial-value="secondEquipment.technicalOrganizationsInit"
                             :show-when-init="true"
                             :start-length=3
                             :disabled="!editMode || secondEquipment.isAip">
        </remote-search-input> -->
        <el-select multiple filterable :collapse-tags="secondEquipment.technicalOrganizations.length > 5"
                    clearable v-model="secondEquipment.technicalOrganizations" size="small"
                    popper-class="search-select" :disabled="!editMode || secondEquipment.isAip">
          <div class="pl-3">
          </div>
          <el-option
            v-for="item in organizations"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <second-equipment-panels-tree-modal v-if="secondEquipmentPanelsTreeModalVisible"
                                        v-model="secondEquipmentPanelsTreeModalVisible"
                                        :data="{id: this.secondEquipment.secondEquipmentPanelId, name: this.secondEquipment.secondEquipmentPanelName}"
                                        @on-update="secondEquipmentPanelUpdate"/>
    <primary-equipments-tree-modal v-if="primaryEquipmentsTreeModalVisible"
                                   v-model="primaryEquipmentsTreeModalVisible"
                                   :data="secondEquipment.primaryEquipmentIdsInit"
                                   @on-update="primaryEquipmentsUpdate"/>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import secondEquipmentsApi from '@/api/nsi/secondEquipments';
// import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import SaveIcon from '@/assets/icons/save.svg';
import validationRules from '@/mixins/validationRules';
import secondEquipmentPanelsTreeModal
  from '@/views/Nsi/SecondEquipments/SecondEquipmentPanelsSelect/SecondEquipmentPanelsTreeModal';
import primaryEquipmentsTreeModal
  from '@/views/Nsi/SecondEquipments/PrimaryEquipmentsSelect/PrimaryEquipmentsTreeModal';
import regApi from '@/api/administration/userRegistration';

export default {
  name: 'SecondEquipmentModal',
  props: ['value', 'secondEquipment'],
  mixins: [validationRules],
  computed: {
    ...mapGetters('dictionaries', ['secondEquipmentPsrTypes', 'baseVoltages']),
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.editMode = false;
        this.$emit('input', val);
      },
    }
  },
  components: {
    // RemoteSearchInput, 
    SaveIcon, secondEquipmentPanelsTreeModal, primaryEquipmentsTreeModal
  },
  data() {
    return {
      editMode: false,
      loading: false,
      isShowFreq: false,
      secondEquipmentPanelsTreeModalVisible: false,
      primaryEquipmentsTreeModalVisible: false,
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        psrTypeId: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
        ],
        secondEquipmentPanelId: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
        ],
        analysisIds: [
          { validator: this.isUUIDList, trigger: 'blur' }
        ],
        mRid: [ 
          { validator: this.isUUID, trigger: 'blur' }
        ]
      },
      datePickerOptions: {
        firstDayOfWeek: 1
      },
      organizations: []
    };
  },
  watch: {
    secondEquipment(val) {
      this.showFreq(val.psrTypeName);
      if (val.frequency) {
        val.frequency /= 1000;
      }
    },
  },
  async mounted() {
    this.$root.$on('addEvent', (x) => {
      this.editMode = x.editMode;
    });
    
    const org = await regApi.getDispatchCenters();
    if (org.data) {
      this.organizations = org.data;
    }
  },
  methods: {
    showFreq(psrTypeName) {
      this.isShowFreq = psrTypeName === 'ПРМ' || psrTypeName === 'ПРД' || psrTypeName === 'Устройство передачи аварийных сигналов и команд';
      if (!this.isShowFreq) {
        this.secondEquipment.frequency = null;
      }
    },
    handleChange(value) {
      const type = this.secondEquipmentPsrTypes.find((el) => el.id === value);
      if (type) {
        this.showFreq(type.name);
        this.secondEquipment.psrTypeName = type.name;
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.editMode = false;
    },
    async addSecondEquipment(secondEquipment) {
      const res = await secondEquipmentsApi.addSecondEquipment(secondEquipment);
      return res;
    },
    async updateSecondEquipment(secondEquipment) {
      const res = await secondEquipmentsApi.updateSecondEquipment(secondEquipment);
      return res;
    },
    async validateForm() {
      const valid = await this.$refs.secondEquipmentForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;

      const request = this.secondEquipment.id ? this.updateSecondEquipment : this.addSecondEquipment;
      this.loading = true;
      if (this.isShowFreq && !Number.isNaN(this.secondEquipment.frequency)) {
        this.secondEquipment.frequency = +this.secondEquipment.frequency * 1000;
      }
      if (!this.isShowFreq) {
        this.secondEquipment.frequency = null;
      }

      const res = await request(this.secondEquipment);

      if (!res.data.succeeded) {
        this.$alert(res.data.resultMessage, 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
      } else {
        if (this.secondEquipment.id) {
          this.$emit('on-update');
        } else {
          this.$emit('on-create');
        }
        this.editMode = false;
        this.dialogVisible = false;
      }
      this.loading = false;
    },
    handleEdit() {
      this.editMode = true;
    },
    handleDelete(id) {
      this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        this.loading = true;
        const res = await secondEquipmentsApi.deleteSecondEquipment(id);
        if (!res.data.succeeded) {
          this.$alert(res.data.resultMessage, 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
          });
        } else {
          this.$emit('on-delete');
          this.dialogVisible = false;
        }
        this.loading = false;
      });
    },
    handleSecondEquipmentPanelsSelect() {
      this.secondEquipmentPanelsTreeModalVisible = true;
    },
    handlePrimaryEquipmentsSelect() {
      this.primaryEquipmentsTreeModalVisible = true;
    },
    secondEquipmentPanelUpdate(panel) {
      if (panel) {
        this.secondEquipment.secondEquipmentPanelId = panel.baseId;
        this.secondEquipment.secondEquipmentPanelName = panel.name;
      } else {
        this.secondEquipment.secondEquipmentPanelId = null;
        this.secondEquipment.secondEquipmentPanelName = null;
      }
    },
    primaryEquipmentsUpdate(data) {
      // console.log(data);
      this.secondEquipment.primaryEquipmentIdsInit = data.map((t) => ({ value: t.name, ...t }));
      this.secondEquipment.primaryEquipmentIds = data.map((t) => t.id);
    }
  },
};
</script>

<style scoped>
</style>
