<template>
  <el-dialog
    top="2em"
    width="70%"
    :visible.sync="dialogVisible"
    append-to-body
    class="types__second-equipments-modal"
    :close-on-click-modal="false"
  >
    <div class="my-flex-container-column">
      <div slot="title" class="pt-0 pb-0">
        Устройства РЗА
      </div> 
      <second-equipment-panels-select-filter @active-change="filterActive = $event" :filter-model="filterModel" @clear-filters="clearFilters"/>
      <second-equipment-panels-select-table 
        :data="data" 
        :filter-active="filterActive" 
        :filter-model="filterModel"
        @on-update="selectedSecondEquipmentPanel = $event" />
    </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="selectSecondEquipmentPanel">Применить</el-button>
        <el-button type="default" @click="cancelSelectSecondEquipmentPanel">Отменить</el-button>
      </div>
  </el-dialog>
</template>

<script>
import SecondEquipmentPanelsSelectFilter from '@/views/Global/SecondEquipmentPanels/SecondEquipmentPanelsFilter.vue';
import SecondEquipmentPanelsSelectTable from './SecondEquipmentPanelsSelectTable';

export default {
  name: 'SecondEquipmentPanelsTreeModal',
  props: ['value', 'data'],
  components: { SecondEquipmentPanelsSelectTable, SecondEquipmentPanelsSelectFilter },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      selectedSecondEquipmentPanel: {},
      secondEquipmentPanels: [],
      filterModel: {
        nameFilter: '',
        eoIdFilter: '',
        skipEoObject: false,
        isLocalNsiFilter: false,
        productAssetModelIdFilter: '',
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        eoRegionFilter: [],
        ownerOrganizationsFilter: [],
        registrationGroupFilter: [],
        executionsFilter: [],
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        technicalOrganizationsFilter: [],
        startCommissioningTimeRange: '',
        endCommissioningTimeRange: '',
        startDecommissioningTimeRange: '',
        endDecommissioningTimeRange: '',
        showDecommissionedSE: null
      },
      filterActive: true,
    };
  },
  methods: {
    selectSecondEquipmentPanel() {      
      this.$emit('on-update', this.selectedSecondEquipmentPanel);
      this.dialogVisible = false;
    },
    cancelSelectSecondEquipmentPanel() {
      this.dialogVisible = false;
    },
    clearFilters() {
      this.filterModel = {
        nameFilter: '',
        eoIdFilter: '',
        productAssetModelIdFilter: '',
        skipEoObject: false,
        isLocalNsiFilter: false,
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        eoRegionFilter: [],
        ownerOrganizationsFilter: [],
        registrationGroupFilter: [],
        executionsFilter: [],
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        technicalOrganizationsFilter: [],
        startCommissioningTimeRange: '',
        endCommissioningTimeRange: '',
        startDecommissioningTimeRange: '',
        endDecommissioningTimeRange: '',
        showDecommissionedSE: null
      };
    }
  }
};
</script>

<style lang="scss">
.types__second-equipments-modal {
  min-height: 1000px;

  .el-dialog__header {
    padding-bottom: 0px;
  }
  .el-dialog__body {
    padding: 0px 10px 0px 10px;
    height: 80vh;
    max-height: 80vh;
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>
