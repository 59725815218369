<template>
  <div class="my-flex-container-column">
    <second-equipments-filter @active-change="filterActive = $event" :filter-model="filterModel" @clear-filters="clearFilters"/>
    <second-equipments-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import SecondEquipmentsFilter from '@/views/Global/SecondEquipmentsSelect/SecondEquipmentsSelectFilter';
import SecondEquipmentsTable from '@/views/Nsi/SecondEquipments/SecondEquipmentsTable';

export default {
  name: 'SecondEquipments',
  components: { SecondEquipmentsTable, SecondEquipmentsFilter },
  data() {
    return {
      filterModel: {
        nameFilter: '',
        psrTypeIdsFilter: [],
        energyObjectNameFilter: [],
        primaryEquipmentNameFilter: [],
        secondEquipmentPanelNameFilter: [],
        skipEoObject: false,
        isLocalNsiFilter: false,
        
        dispatchAdministrationOrganizationsFilter: [],
        dispatchManagementOrganizationsFilter: [],
        eoRegionFilter: [],
        ownerOrganizationsFilter: [],
        registrationGroupFilter: [],
        executionsFilter: [],
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        technicalOrganizationsFilter: [],
        startCommissioningTimeRange: '',
        endCommissioningTimeRange: '',
        startDecommissioningTimeRange: '',
        endDecommissioningTimeRange: '',
        showDecommissionedSE: null
      },
      filterActive: true
    };
  },
  methods: {
    clearFilters() {
      this.filterModel = {
        nameFilter: '',
        psrTypeIdsFilter: [],
        energyObjectNameFilter: [],
        primaryEquipmentNameFilter: [],
        secondEquipmentPanelNameFilter: [],
        skipEoObject: false,
        isLocalNsiFilter: false,

        dispatchAdministrationOrganizationsFilter: [],
        dispatchManagementOrganizationsFilter: [],
        eoRegionFilter: [],
        ownerOrganizationsFilter: [],
        registrationGroupFilter: [],
        executionsFilter: [],
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        technicalOrganizationsFilter: [],
        startCommissioningTimeRange: '',
        endCommissioningTimeRange: '',
        startDecommissioningTimeRange: '',
        endDecommissioningTimeRange: '',
        showDecommissionedSE: null
      };
    }
  }
};
</script>

<style scoped>

</style>
