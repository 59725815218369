<template>
  <div class="my-flex-container-column">
    <el-row class="m-0" :gutter="20">
    <div>
      <el-button type="text" @click="clearSelectedPrimaryEquipments" icon="el-icon-circle-close">Очистить все</el-button>
      <div class="selected-tags">
        <el-tag
          v-for="tag in selectedPrimaryEquipmentsDistinct"
          :key="tag.id"
          :closable="!tag.disabled"
          size="small"
          @close="handlePrimaryEquipmentDelete(tag)"
          class="mr-1 mb-1">
          {{tag.name}}
        </el-tag>
      </div>
    </div>
    </el-row>
    <el-row class="m-0" :gutter="20">
        <el-col :span="12">
        <pagination
            style="text-align: center"
            :total-count="totalCount"
            :page-size.sync="pageSize"
            :current-page.sync="pageNumber"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
        </el-col>
    </el-row>
    <div>
      <el-button @click="toggleAllNodes" type="text" class="ml-2">Свернуть/развернуть всё</el-button>
      <span class="total_items">Всего: {{this.primaryEquipmentsCount}}</span>
    </div>
    <div v-loading="loading" class="my-flex-data-content">
        <infinite-tree
          ref="tree"
          :data="primaryEquipments"
          :style="{'height' : '100%'}"
          @on-check-click="handleCheck"
          @on-open-node="applyChecking"
        >
          <div class="infinite-tree-node" slot-scope="{ node }">{{ node.color }}</div>
        </infinite-tree>
    </div>
  </div>
</template>



<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import primaryEquipmentsApi from '@/api/nsi/primaryEquipments';
import pagination from '@/components/Ui/Pagination';
import infiniteTree from '@/components/Ui/InfiniteTree/InfiniteTree';

export default {
  name: 'PrimaryEquipmentsTable',
  props: {
    filterModel: { type: Object, default: () => {} },
    filterActive: { type: Boolean, default: () => true },
    data: { type: Array, default: () => [] },
  },
  components: {
    pagination,
    infiniteTree
    },
  data() {
    return {
      loading: false,
      primaryEquipments: [],
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      dialogVisible: false,
      selectedPrimaryEquipments: [],
      selectedPrimaryEquipmentsDistinct: [],
      primaryEquipmentsCount: 0
    };
  },
  watch: {
    filterModel: {
      handler() {
        this.debouncedGetData();
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
    treeContainer() {
        return this.$refs.tree;
    }
  },
  async mounted() {
    await this.getPrimaryEquipments();
  },
  created() {
    this.debouncedGetData = debounce(this.getPrimaryEquipments, 500);
    this.selectedPrimaryEquipmentsDistinct = this.data.map((t) => ({ baseId: t.id, name: t.value, ...t }));
  },
  methods: {
    async getPrimaryEquipments() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        nameFilter: this.filterModel.nameFilter,
        energyObjectIdFilter: this.filterModel.energyObjectIdFilter,
        duOrganizationName: this.filterModel.duOrganizationName,
        dvOrganizationName: this.filterModel.dvOrganizationName,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        primaryEquipmentTypeIdFilter: this.filterModel.primaryEquipmentTypeIdFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        isRuFilter: this.filterModel.isRuFilter,
        hideLines: false,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter
      };
      const res = await primaryEquipmentsApi.getPrimaryEquipmentsWithPagination(params);   
      if (res.data) {
        this.primaryEquipments = res.data.items;
        this.totalCount = res.data.totalCount;
        this.primaryEquipmentsCount = res.data.treeItemsCount;

        this.treeContainer.tree.loadData(this.primaryEquipments);
        this.applyChecking();

        // обход дерева
        const { nodes } = this.treeContainer.tree;
        this.selectedPrimaryEquipmentsDistinct.forEach((primaryEquipment) => {
          nodes.forEach((node) => {
            if (node.baseId === primaryEquipment.id) {
              node.state.checked = true;
            }
          });
        });
        this.treeContainer.rerender();
        this.loading = false;
      }
    },
    applyChecking() {      
      const { nodes } = this.treeContainer.tree;
      nodes.forEach((node) => {
        if (node.nodeType === 'primaryEquipment') {
          node.сheckAllowed = true;
        }
      });
      this.treeContainer.rerender();
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getPrimaryEquipments();
    },
    async handleCurrentChange() {
      await this.getPrimaryEquipments();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }
      await this.getPrimaryEquipments();
    },
    handlePrimaryEquipmentDelete(primaryEquipment) {
      this.$nextTick(() => {
        this.selectedPrimaryEquipmentsDistinct = this.selectedPrimaryEquipmentsDistinct.filter((s) => s.baseId !== primaryEquipment.id);
        this.selectedPrimaryEquipments = this.selectedPrimaryEquipments.filter((s) => s.baseId !== primaryEquipment.id);
        this.$refs.tree.setCheckedKeys(this.selectedPrimaryEquipments);
        this.$emit('on-update', this.selectedPrimaryEquipmentsDistinct);
      });
    },
    handleCheck(node) {
      if (node.state.checked) {
        this.selectedPrimaryEquipments.push(node);
        if (!this.selectedPrimaryEquipmentsDistinct.some((t) => t.baseId === node.baseId)) {
          this.selectedPrimaryEquipmentsDistinct.push({ id: node.baseId, name: node.name, value: node.name });
        }

        const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'primaryEquipment' && t.baseId === node.baseId);
        if (nodes && nodes.length > 0) {
          nodes.forEach((n) => {
            n.state.checked = true;
          });

          this.treeContainer.rerender();
        }
        this.$emit('on-update', this.selectedPrimaryEquipmentsDistinct);
      } else {
        const index = this.selectedPrimaryEquipments.findIndex((x) => x.baseId === node.baseId);
        if (index > -1) {
          this.selectedPrimaryEquipments.splice(index, 1);
          this.selectedPrimaryEquipmentsDistinct = this.selectedPrimaryEquipmentsDistinct.filter((item) => item.id !== node.baseId);
          const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'primaryEquipment' && t.baseId === node.baseId);
          if (nodes && nodes.length > 0) {
            nodes.forEach((n) => {
              n.state.checked = false;
            });

            this.treeContainer.rerender();
          }
          this.$emit('on-update', this.selectedPrimaryEquipmentsDistinct);
        }        
      }
    },    
    clearSelectedPrimaryEquipments() {
      this.selectedPrimaryEquipmentsDistinct = [];
      this.selectedPrimaryEquipments = [];

      const { nodes } = this.treeContainer.tree;
      nodes.forEach((node) => {
        if (node.nodeType === 'primaryEquipment') {
          node.state.checked = false;
        }
      });

      this.treeContainer.rerender();
      this.$emit('on-update', this.selectedPrimaryEquipmentsDistinct);
    },
    async toggleAllNodes() {
      await this.$refs.tree.toggleNodes();
      this.applyChecking();
    }
  }
};
</script>

<style scoped>
.total_items {
   float: right; 
   color: #909399;
   font-family: Montserrat-Regular;
   font-size: 14px;
   margin-top: 12px;
   margin-right: 8px;
}
</style>
