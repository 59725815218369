<template>
  <div class="my-flex-container-column">
    <el-row class="m-0" :gutter="20">
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleCreate" type="text" icon="el-icon-circle-plus-outline" :disabled="mergeMode">Добавить</el-button>
      </el-col>
      <el-col :span="3">
      <el-button @click="handleDownLoad" type="text"
                  :loading="loading"
                   icon="el-icon-download">Выгрузить
        </el-button>
      </el-col>
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleMergeMode" type="text" icon="el-icon-share">Объединить</el-button>
      </el-col>
      <el-col v-if="userCanEdit && mergeMode" :span="3">
        <el-button @click="handleMergeModeCancel" type="text" icon="el-icon-circle-close" >Отменить</el-button>
      </el-col>
      <el-col :span="12">
        <pagination style="text-align: center"
                    :total-count="totalCount"
                    :page-size.sync="pageSize"
                    :current-page.sync="pageNumber"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" />
      </el-col>
    </el-row>
    <div>
      <el-button @click="toggleAllNodes" type="text" class="ml-2">Свернуть/развернуть всё</el-button>
        <span class="total_items">Всего: {{this.seCount}}</span>
    </div>
    <div v-loading="loading" class="my-flex-data-content">

        <infinite-tree
          ref="tree"
          :data="secondEquipments"
          :style="{'height' : '100%'}"
          @on-view-click="handleView"
          @on-check-click="handleCheck"
        >
          <div class="infinite-tree-node" slot-scope="{ node }">{{ node.color }}</div>
        </infinite-tree>

    <second-equipment-modal @on-update="getSecondEquipments" @on-create="getSecondEquipments()" @on-delete="getSecondEquipments()"
                            v-model="dialogVisible" :secondEquipment="dialogSecondEquipment"></second-equipment-modal>
    <second-equipments-merge-modal v-model="dialogMergeVisible" :items="mergingItems" @on-merge="handleMerge($event)" @on-merge-item-remove="handleMergeItemRemove($event)"></second-equipments-merge-modal>
    </div></div>
</template>



<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import secondEquipmentsApi from '@/api/nsi/secondEquipments';
import pagination from '@/components/Ui/Pagination';
import infiniteTree from '@/components/Ui/InfiniteTree/InfiniteTree';
import xlsUpDownHelper from '@/mixins/xlsUpDownHelper';
import SecondEquipmentModal from './SecondEquipmentModal';
import SecondEquipmentsMergeModal from './SecondEquipmentsMergeModal';

export default {
  name: 'SecondEquipmentsTable',
  props: {
    filterModel: { type: Object, default: () => {} },
    filterActive: { type: Boolean, default: () => true },
    fromEventCard: { type: Boolean, default: () => false }
  },
  mixins: [xlsUpDownHelper],
  components: {
    infiniteTree,
    pagination,
    SecondEquipmentModal,
    SecondEquipmentsMergeModal
  },
  data() {
    return {
      loading: false,
      secondEquipments: [],
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      dialogVisible: false,
      dialogSecondEquipment: {},
      mergeMode: false,
      mergingItems: [],
      dialogMergeVisible: false,
      seCount: 0
    };
  },
  watch: {
    filterModel: {
      handler() {
        this.debouncedGetData();
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
    treeContainer() {
        return this.$refs.tree;
    }
  },
  async created() {
    this.debouncedGetData = debounce(this.getSecondEquipments, 500);
    // this.$nextTick(() => {
    //   this.tableHeight = this.getTableHeight();
    // });
    await this.getSecondEquipments();
    // this.tableHeight = this.getTableHeight();
  },
  methods: {
    async handleDownLoad() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.totalCount,
        primaryEquipmentIdFilter: null,
        secondEquipmentPanelIdFilter: null,
        nameFilter: this.filterModel.nameFilter,
        psrTypeIdsFilter: this.filterModel.psrTypeIdsFilter,
        energyObjectNameFilter: this.filterModel.energyObjectNameFilter,
        primaryEquipmentNameFilter: this.filterModel.primaryEquipmentNameFilter,
        secondEquipmentPanelNameFilter: this.filterModel.secondEquipmentPanelNameFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        fromEventCard: this.fromEventCard,

        dispatchAdministrationOrganizationsFilter: this.filterModel.dispatchAdministrationOrganizationsFilter,
        dispatchManagementOrganizationsFilter: this.filterModel.dispatchManagementOrganizationsFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        registrationGroupFilter: this.filterModel.registrationGroupFilter,
        executionsFilter: this.filterModel.executionsFilter,  
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter,
        technicalOrganizationsFilter: this.filterModel.technicalOrganizationsFilter,
        startCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.filterModel.periodCommissioningTimeRange[0] : null,
        endCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.filterModel.periodCommissioningTimeRange[1] : null,
        startDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.filterModel.periodDecommissioningTimeRange[0] : null,
        endDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.filterModel.periodDecommissioningTimeRange[1] : null,
        showDecommissionedSE: this.filterModel.showDecommissionedSE
      };
      const res = await secondEquipmentsApi.getSecondEquipmentsWithPagination(params);
      
      this.loading = false;
      if (res.data) {
        await this.exportToExcelTree('Исполнитель документа', res.data.items);
      }
      this.loading = false;      
    },
    async getSecondEquipments() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        primaryEquipmentIdFilter: null,
        secondEquipmentPanelIdFilter: null,
        nameFilter: this.filterModel.nameFilter,
        psrTypeIdsFilter: this.filterModel.psrTypeIdsFilter,
        energyObjectNameFilter: this.filterModel.energyObjectNameFilter,
        primaryEquipmentNameFilter: this.filterModel.primaryEquipmentNameFilter,
        secondEquipmentPanelNameFilter: this.filterModel.secondEquipmentPanelNameFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        fromEventCard: this.fromEventCard,

        dispatchAdministrationOrganizationsFilter: this.filterModel.dispatchAdministrationOrganizationsFilter,
        dispatchManagementOrganizationsFilter: this.filterModel.dispatchManagementOrganizationsFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        registrationGroupFilter: this.filterModel.registrationGroupFilter,
        executionsFilter: this.filterModel.executionsFilter,  
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter,
        technicalOrganizationsFilter: this.filterModel.technicalOrganizationsFilter,
        startCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.filterModel.periodCommissioningTimeRange[0] : null,
        endCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.filterModel.periodCommissioningTimeRange[1] : null,
        startDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.filterModel.periodDecommissioningTimeRange[0] : null,
        endDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.filterModel.periodDecommissioningTimeRange[1] : null,
        showDecommissionedSE: this.filterModel.showDecommissionedSE
      };
      const res = await secondEquipmentsApi.getSecondEquipmentsWithPagination(params);
      if (res.data) {
        this.secondEquipments = res.data.items;
        this.totalCount = res.data.totalCount;
        this.seCount = res.data.treeItemsCount;

        this.treeContainer.tree.loadData(this.secondEquipments);
        this.applyIsMerging(this.mergeMode);
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getSecondEquipments();
    },
    async handleCurrentChange() {
      await this.getSecondEquipments();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }
      await this.getSecondEquipments();
    },
    async handleView(node) {
      const res = await secondEquipmentsApi.getSecondEquipmentItem(node.baseId);
      this.dialogSecondEquipment = res.data;
      this.dialogVisible = true;
    },
    handleCheck(node) {
      if (node.state.checked) {
        this.mergingItems.push(node);

        const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'secondEquipment' && t.baseId === node.baseId);
        if (nodes && nodes.length > 0) {
          nodes.forEach((n) => {
            n.state.checked = true;
          });

          this.treeContainer.rerender();
        }
      } else {
        const index = this.mergingItems.findIndex((x) => x.baseId === node.baseId);
        if (index > -1) {
          this.mergingItems.splice(index, 1);

          const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'secondEquipment' && t.baseId === node.baseId);
          if (nodes && nodes.length > 0) {
            nodes.forEach((n) => {
              n.state.checked = false;
            });

            this.treeContainer.rerender();
          }
        }
      }
    },
    handleCreate() {
        this.dialogSecondEquipment = {
          id: null,
          name: null,
          productAssetModelId: '',
          startDate: null,
          endDate: null,
          psrTypeId: '',
          secondEquipmentPanelId: '',
          // normallyInService: false,
          technicalOrganizations: [],
          primaryEquipmentIds: [],
          isAip: false,
          frequency: null,

          dispatchAdministrationOrganizationsFilter: [],
          dispatchManagementOrganizationsFilter: [],
          eoRegionFilter: [],
          ownerOrganizationsFilter: [],
          registrationGroupFilter: [],
          executionsFilter: [],
          techAdministrationOrganizationsFilter: [],
          techManagementOrganizationsFilter: [],
          technicalOrganizationsFilter: [],
          startCommissioningTimeRange: '',
          endCommissioningTimeRange: '',
          startDecommissioningTimeRange: '',
          endDecommissioningTimeRange: '',
          showDecommissionedSE: null
        };
        this.dialogVisible = true;
        this.$root.$emit('addEvent', {
          editMode: true,
        });
    },
    applyIsMerging(isMerging) {
      const { nodes } = this.treeContainer.tree;

      nodes.forEach((node) => {
        if (node.nodeType === 'secondEquipment') {
          if (isMerging && this.mergingItems.some((t) => t.baseId === node.baseId)) {
            node.state.checked = true;
          }

          if (!isMerging) node.state.checked = false;

          node.сheckAllowed = isMerging;
          node.viewAllowed = !isMerging;
        }
      });

      this.treeContainer.rerender();
    },
    handleMergeItemRemove(item) {
      const index = this.mergingItems.findIndex((x) => x.id === item.id);
      if (index > -1) {
        item.state.checked = false;
        this.handleCheck(item);

        if (this.mergingItems.length <= 1) {
          this.dialogMergeVisible = false;
          this.$alert('Необходимо выбрать 2 или более записей', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        }
      }
    },
    async handleMerge(data) {
      if (data.secondaryItems.some((x) => x.isAip)) {
        this.$alert('Невозможно объединить несколько корпоративных записей или использовать корпоративную запись в качестве присоединяемой', 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
        return;
      }

      this.loading = true;

      const res = await secondEquipmentsApi.mergeSecondEquipments(data.mainItem.baseId, data.secondaryItems.map((x) => x.baseId));
      if (!res.data.succeeded) {
        this.$alert(res.data.resultMessage, 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
      } else {
        this.dialogMergeVisible = false;
        this.handleMergeModeCancel();
        await this.getSecondEquipments();
      }
      this.loading = false;
    },
    handleMergeMode() {
      if (!this.mergeMode) {
        this.mergeMode = true;
        this.applyIsMerging(this.mergeMode);
      } else {
        if (this.mergingItems.length <= 1) {
          this.$alert('Необходимо выбрать 2 или более записей', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        } else {
          this.dialogMergeVisible = true;
        }
      }
    },
    handleMergeModeCancel() {
      this.mergingItems.splice(0);
      this.mergeMode = false;
      this.applyIsMerging(this.mergeMode);
    },
    toggleAllNodes() {
      this.$refs.tree.toggleNodes();
      // this.applyChecking();
    }
  }
};
</script>

<style scoped>
.total_items {
   float: right;
   color: #909399;
   font-family: Montserrat-Regular;
   font-size: 14px;
   margin-top: 12px;
   margin-right: 8px;
}
</style>
